import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AfterLogin from "./components/afterLogin";
import AfterPayment from "./components/afterPayment";
import BeforeLogin from "./components/beforeLogin";
import InitiatePayment from "./components/initiatePayment";
import InitiateAgreement from "./components/initiateAgreement";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<BeforeLogin />} />
          <Route path="/after-login" element={<AfterLogin />} />
          <Route path="/initiate-payment" element={<InitiatePayment />} />
          <Route path="/initiate-agreement" element={<InitiateAgreement />} />
          <Route
            path="/after-agreement"
            element={<AfterPayment isAgreement />}
          />
          <Route path="/after-payment" element={<AfterPayment />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
