import React from "react";
import realfagsPortalen from "./RealfagsportalenLogo.png";

interface Props {
  isMobile: boolean;
}

const RealfagsPortalenLogo: React.FC<Props> = ({ isMobile }) => {
  return (
    <>
      {isMobile ? (
        <img src={realfagsPortalen} width={"300px"} alt="logo" />
      ) : (
        <img src={realfagsPortalen} className="App-logo" alt="logo" />
      )}
    </>
  );
};

export default RealfagsPortalenLogo;
