import { Stack, Tooltip, Typography } from "@mui/material";

interface Props {
  isDiscountOpen: boolean;
  onIsDiscountOpenChange: (isPresent: boolean) => void;
}

const DiscountToggle: React.FC<Props> = ({
  isDiscountOpen,
  onIsDiscountOpenChange,
}) => {
  return (
    <Stack direction={"row"} justifyContent={"space-between"} width={"100%"}>
      <Tooltip
        title={
          isDiscountOpen ? (
            <Typography>Lukk</Typography>
          ) : (
            <Typography>
              Dersom du har en rabattkode kan du fylle den inn her for å få
              rabatt på kurset.
            </Typography>
          )
        }
      >
        <span>
          <Typography
            variant="h6"
            onClick={() => onIsDiscountOpenChange(!isDiscountOpen)}
            style={{
              cursor: "pointer",
            }}
          >
            {isDiscountOpen ? (
              <b>
                <u>Fjern</u>
              </b>
            ) : (
              <b>
                <u>Rabattkode?</u>
              </b>
            )}
          </Typography>
        </span>
      </Tooltip>
    </Stack>
  );
};

export default DiscountToggle;
