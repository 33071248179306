export const internalServerError =
  "Noe gikk galt. Teamet vårt har fått beskjed, kontakt oss hvis feilen vedvarer.";

export const shouldConfirmLoginUrl = "payment%3Fproduct_id%3Dtestkurs";

export const realfagsPortalenBaseUrl = "https://www.realfagsportalen.no";
export const realfagsPortalenIntegrationBaseUrl =
  "https://realfagsportalen-integration.herokuapp.com";

export const realfagsPortalenRecruitedBy = "realfagsPortalenRecruitedBy";
export const realfagsPortalenRedirectUrl = "realfagsPortalenRedirectUrl";

export const anonymousUser = "Anonymous user";
