import { Stack, Tooltip, Typography } from "@mui/material";

interface Props {
  isPresent: boolean;
  onIsPresentChange: (isPresent: boolean) => void;
}

const GiftToggle: React.FC<Props> = ({ isPresent, onIsPresentChange }) => {
  return (
    <Stack direction={"row"} justifyContent={"space-between"} width={"100%"}>
      <Tooltip
        title={
          isPresent ? (
            <Typography>Returner til å kjøpe kurset til egen bruker</Typography>
          ) : (
            <Typography>
              For å kjøpe kurset i gave, fyller du inn telefonnummeret til den
              som skal få kurset. Mottaker får automatisk tilgang til kurset ved
              å registrere seg eller logge inn på realfagsportalen.no
            </Typography>
          )
        }
      >
        <span>
          <Typography
            variant="h6"
            onClick={() => onIsPresentChange(!isPresent)}
            style={{
              cursor: "pointer",
            }}
          >
            {isPresent ? (
              <b>
                <u>Fjern</u>
              </b>
            ) : (
              <b>
                <u>Kjøp som gave?</u>
              </b>
            )}
          </Typography>
        </span>
      </Tooltip>
    </Stack>
  );
};

export default GiftToggle;
