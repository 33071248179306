import mixpanel, { Dict } from "mixpanel-browser";
mixpanel.init("2934a59fabfed231154af1b46ff9b542");

let env_check = process.env.NODE_ENV === "production";

let actions = {
  identify: (id: string) => {
    if (env_check) mixpanel.identify(id);
  },
  track: (name: string, props: Dict) => {
    if (env_check) mixpanel.track(name, props);
  },
};

export let Mixpanel = actions;
