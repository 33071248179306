import axios, { AxiosRequestConfig } from "axios";
import qs from "qs";
import { useEffect, useState } from "react";
import { MutatingDots } from "react-loader-spinner";
import { anonymousUser, internalServerError } from "../constants";
import { getIsMobile } from "../utils/helpers";
import { Mixpanel } from "../utils/mixpanel";
import "./App.css";
import RealfagsPortalenLogo from "./RealfagsportalenLogo";
import paymentError from "./paymentError.png";
import { realfagsportalenUrl } from "./initiatePayment";

interface AfterPaymentProps {
  isAgreement?: boolean;
}

function AfterPayment({ isAgreement }: AfterPaymentProps) {
  const [courseUrl, setCourseUrl] = useState<string>("");
  const [error, setError] = useState<string | undefined>(undefined);
  const [paymentFail, setPaymentFail] = useState<boolean>(false);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const orderId = urlParams.get("orderId");
  const ids = orderId && orderId.split("--");
  const courseId = ids && ids[0];
  const urlPostfix = isAgreement ? "after-agreement" : "after";

  useEffect(() => {
    var data = JSON.stringify({ orderId });
    const options: AxiosRequestConfig = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      data,
    };
    axios(`${realfagsportalenUrl}payment/${urlPostfix}`, options)
      .then((res) => {
        if (res.data.paymentReserved && res.data.courseId) {
          setCourseUrl(`https://www.realfagsportalen.no/takk-for-kjopet`);
          Mixpanel.identify(orderId?.split("--")[1] ?? anonymousUser);
          Mixpanel.track("Payment reserved", { orderId });
        } else if (res.data.paymentCancelled) {
          setPaymentFail(true);
          Mixpanel.identify(orderId?.split("--")[1] ?? anonymousUser);
          Mixpanel.track("Payment failed", { orderId });
        }
      })
      .catch((err) => {
        const errResponse = err.response;
        if (
          errResponse &&
          errResponse.status === 400 &&
          errResponse.data &&
          errResponse.data.message
        ) {
          setError(errResponse.data.message);
        } else {
          setError(internalServerError);
        }
      });
  }, []);

  useEffect(() => {
    if (courseUrl) {
      window.location.href = courseUrl;
    }
  }, [courseUrl]);
  const informationText = "Bekrefter betaling...";

  const informationOnFailed = (
    <p>
      {"Betalingen feilet eller ble kansellert, trykk "}
      <u
        onClick={() =>
          setCourseUrl(`https://www.realfagsportalen.no/course/${courseId}`)
        }
        style={{
          cursor: "pointer",
          color: "blue",
        }}
      >
        her
      </u>
      {" for å gå tilbake til kurset"}
    </p>
  );

  const informationOnError = (
    <p>
      <p>{error}</p>
      {"Trykk "}
      <u
        onClick={() => setCourseUrl(`https://www.realfagsportalen.no/`)}
        style={{
          cursor: "pointer",
          color: "blue",
        }}
      >
        her
      </u>
      {" for å gå tilbake til forsiden."}
    </p>
  );
  const displayMessage = error ? informationOnError : <p>{informationText}</p>;
  const isMobile = getIsMobile(window.navigator.userAgent);
  return (
    <div className="App">
      {paymentFail ? (
        <img
          src={paymentError}
          className="App-logo"
          height={"450"}
          width={"600"}
          alt="logo"
        />
      ) : (
        <RealfagsPortalenLogo isMobile={isMobile} />
      )}
      {!error && !paymentFail && (
        <div style={{ alignItems: "center" }}>
          <MutatingDots
            width="80"
            color="#ffd43b"
            secondaryColor="#ffd43b"
            ariaLabel="three-dots-loading"
          />
        </div>
      )}
      {paymentFail ? informationOnFailed : displayMessage}
    </div>
  );
}

export default AfterPayment;
