import { Card, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { anonymousUser } from "../../constants";
import { getIsMobile } from "../../utils/helpers";
import { Mixpanel } from "../../utils/mixpanel";
import VippsButton from "../VippsButton";

interface AgreementCheckoutPageProps {
  courseId: string;
  price: number;
  onSubmit: () => void;
}

export const courseIdToPrettyCourseId: Record<string, string> = {
  "matematikk-r1": "Matematikk R1",
  "matematikk-r2": "Matematikk R2",
  "matematikk-s1": "Matematikk S1",
  "matematikk-s2": "Matematikk S2",
  "matematikk-1p": "Matematikk 1P",
  "matematikk-2p": "Matematikk 2P",
  "matematikk-1t": "Matematikk 1T",
  "eksamenskurs-1t": "Eksamenskurs 1T",
  "r1-eksamenskurs": "Eksamenskurs R1",
};

const AgreementCheckoutPage: React.FC<AgreementCheckoutPageProps> = ({
  courseId,
  price,
  onSubmit,
}) => {
  const prettyCourseId = courseIdToPrettyCourseId[courseId]
    ? courseIdToPrettyCourseId[courseId]
    : courseId;

  const isMobile = getIsMobile(window.navigator.userAgent);

  useEffect(() => {
    Mixpanel.identify(anonymousUser);
    Mixpanel.track("Checkout page entered", {
      courseId,
    });
  }, []);

  return (
    <Card
      sx={{
        borderRadius: "20px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      }}
    >
      <Stack
        direction="column"
        spacing={2}
        alignItems="center"
        padding={4}
        sx={{
          overflowY: "auto",
          maxHeight: "98%",
          overflowX: "hidden",
        }}
      >
        <Stack direction={"row"} width={"100%"} paddingX={6}>
          <Typography variant="h4">Oppsumering av avtale</Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          width={"100%"}
        >
          <Stack direction={"row"}>
            <Typography variant="h6">Kurs:</Typography>
          </Stack>
          <Stack direction={"row"} width={"15%"}>
            <Typography variant="h6">Pris:</Typography>
          </Stack>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          width={"100%"}
        >
          <Stack direction={"row"}>
            <Typography variant="h6">{`${prettyCourseId} første mnd`}</Typography>
          </Stack>
          <Stack direction={"row"} width={"15%"}>
            <Typography variant="h6" sx={{ whiteSpace: "nowrap" }}>
              {price} kr/mnd
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          width={"100%"}
        >
          <Stack direction={"row"}>
            <Typography variant="h6">{prettyCourseId}</Typography>
          </Stack>
          <Stack direction={"row"} width={"15%"}>
            <Typography variant="h6" sx={{ whiteSpace: "nowrap" }}>
              {price} kr/mnd
            </Typography>
          </Stack>
        </Stack>
        <VippsButton
          onClick={() => {
            onSubmit();
          }}
        />
      </Stack>
    </Card>
  );
};

export default AgreementCheckoutPage;
