import React from "react";
import {
  Card,
  Typography,
  Button,
  TextField,
  Stack,
  Tooltip,
  Divider,
} from "@mui/material";
import { useState } from "react";
import VippsButton from "../VippsButton";
import { getIsMobile } from "../../utils/helpers";
import { courseIdToPrettyCourseId } from ".";

interface CheckoutPageProps {
  courseId: string;
  price: number;
  onSubmit: () => void;
  onPhoneNumberChange: (phoneNumber: string) => void;
  discount?: number;
}

const PresentCheckoutPage: React.FC<CheckoutPageProps> = ({
  courseId,
  price,
  discount,
  onSubmit,
  onPhoneNumberChange,
}) => {
  const prettyCourseId = courseIdToPrettyCourseId[courseId]
    ? courseIdToPrettyCourseId[courseId]
    : courseId;
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const validatePhoneNumber = (phoneNumber?: string) => {
    return (
      phoneNumber && phoneNumber.length === 8 && !isNaN(Number(phoneNumber))
    );
  };
  const numberIsValid = validatePhoneNumber(phoneNumber);

  const isMobile = getIsMobile(window.navigator.userAgent);
  if (isMobile) {
    return (
      <Card
        sx={{
          borderRadius: "20px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          width: "98%",
        }}
      >
        <Stack
          direction="column"
          spacing={2}
          alignItems="center"
          padding={4}
          sx={{
            overflowY: "auto",
            maxHeight: "90%",
            overflowX: "hidden",
          }}
        >
          <Stack direction={"row"} width={"100%"} paddingX={6}>
            <Typography variant="h4">Oppsumering av ordre</Typography>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            width={"100%"}
          >
            <Stack direction={"row"}>
              <Typography variant="h6">Kurs:</Typography>
            </Stack>
            <Stack direction={"row"} width={"15%"}>
              <Typography variant="h6">Pris:</Typography>
            </Stack>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            width={"100%"}
          >
            <Stack direction={"row"}>
              <Typography variant="h6">{prettyCourseId}</Typography>
            </Stack>
            <Stack direction={"row"} width={"15%"}>
              <Typography variant="h6" sx={{ whiteSpace: "nowrap" }}>
                {price} kr
              </Typography>
            </Stack>
          </Stack>
          {!!discount && discount > 0 && (
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              <Stack direction={"row"}>
                <Typography variant="h6">Rabatt</Typography>
              </Stack>
              <Stack direction={"row"} width={"15%"}>
                <Typography variant="h6" sx={{ whiteSpace: "nowrap" }}>
                  -{discount} kr
                </Typography>
              </Stack>
            </Stack>
          )}
          <Divider orientation="horizontal" flexItem />
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            width={"100%"}
          >
            <Stack direction={"row"}>
              <Typography variant="h6">Total:</Typography>
            </Stack>
            <Stack direction={"row"} width={"15%"}>
              <Typography variant="h6" sx={{ whiteSpace: "nowrap" }}>
                {price - (discount ?? 0)} kr
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction={"column"}
            maxWidth={"500px"}
            width="100%"
            paddingTop={3}
            justifyContent={"start"}
          >
            <Typography variant="subtitle1" width={"90%"}>
              Fyll inn telefonnummeret (8 siffer) til den som skal få kurset i
              gave:
            </Typography>
          </Stack>

          <Stack
            direction="column"
            gap={1}
            width={"100%"}
            alignItems={"center"}
          >
            <TextField
              id="phoneNumber"
              label="Telefonnummer"
              value={phoneNumber}
              placeholder="feks: 12345678"
              onChange={(e) => setPhoneNumber(e.target.value)}
              size="small"
              sx={{ minWidth: "250px" }}
            />
            <Tooltip
              title={
                numberIsValid ? (
                  ""
                ) : (
                  <Typography>
                    Fyll inn et gyldig telefonnummer for å fortsette
                  </Typography>
                )
              }
            >
              <span>
                <VippsButton
                  disabled={!numberIsValid}
                  onClick={() => {
                    if (!phoneNumber) {
                      return;
                    }
                    onPhoneNumberChange(phoneNumber);
                    onSubmit();
                  }}
                />
              </span>
            </Tooltip>
          </Stack>
        </Stack>
      </Card>
    );
  }

  return (
    <Card
      sx={{
        borderRadius: "20px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      }}
    >
      <Stack
        direction="column"
        spacing={2}
        alignItems="center"
        padding={4}
        sx={{
          overflowY: "auto",
          maxHeight: "98%",
          overflowX: "hidden",
        }}
      >
        <Stack direction={"row"} width={"100%"} paddingX={6}>
          <Typography variant="h5">Oppsumering av ordre</Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          width={"100%"}
        >
          <Stack direction={"row"}>
            <Typography variant="h6">Kurs:</Typography>
          </Stack>
          <Stack direction={"row"} width={"15%"}>
            <Typography variant="h6">Pris:</Typography>
          </Stack>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          width={"100%"}
        >
          <Stack direction={"row"}>
            <Typography variant="h6">{prettyCourseId}</Typography>
          </Stack>
          <Stack direction={"row"} width={"15%"}>
            <Typography variant="h6" sx={{ whiteSpace: "nowrap" }}>
              {price} kr
            </Typography>
          </Stack>
        </Stack>
        {!!discount && discount > 0 && (
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            width={"100%"}
          >
            <Stack direction={"row"}>
              <Typography variant="h6">Rabatt</Typography>
            </Stack>
            <Stack direction={"row"} width={"15%"}>
              <Typography variant="h6" sx={{ whiteSpace: "nowrap" }}>
                -{discount} kr
              </Typography>
            </Stack>
          </Stack>
        )}
        <Divider orientation="horizontal" flexItem />
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          width={"100%"}
        >
          <Stack direction={"row"}>
            <Typography variant="h6">Total:</Typography>
          </Stack>
          <Stack direction={"row"} width={"15%"}>
            <Typography variant="h6" sx={{ whiteSpace: "nowrap" }}>
              {price - (discount ?? 0)} kr
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction={"column"}
          maxWidth={"500px"}
          width="100%"
          paddingTop={3}
          justifyContent={"start"}
        >
          <Typography variant="subtitle1" width={"90%"}>
            Fyll inn telefonnummeret (8 siffer) til den som skal få kurset i
            gave:
          </Typography>
        </Stack>

        <Stack direction="row" gap={2} width={"100%"} alignItems={"center"}>
          <TextField
            id="phoneNumber"
            label="Telefonnummer"
            value={phoneNumber}
            placeholder="feks: 12345678"
            onChange={(e) => setPhoneNumber(e.target.value)}
            size="small"
          />
          <Tooltip
            title={
              numberIsValid ? (
                ""
              ) : (
                <Typography>
                  Fyll inn et gyldig telefonnummer for å fortsette
                </Typography>
              )
            }
          >
            <span>
              <VippsButton
                disabled={!numberIsValid}
                onClick={() => {
                  if (!phoneNumber) {
                    return;
                  }
                  onPhoneNumberChange(phoneNumber);
                  onSubmit();
                }}
              />
            </span>
          </Tooltip>
        </Stack>
      </Stack>
    </Card>
  );
};

export default PresentCheckoutPage;
